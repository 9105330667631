<template>
    <div class="dropdown" v-show="availableLocales.length > 1">
        <button class="btn btn-sm btn-light dropdown-toggle border" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{$t(`js.language_name`)}}
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" href="#" @click="setLocale(locale)" v-for="locale in availableLocales">{{ $t(`js.language_name`, locale) }}</a>
        </div>
    </div>
</template>

<script>
    import {mapMutations, mapState} from "vuex"

    export default {
        name: "LocaleSwitcher",
        computed: {
            ...mapState(['locales']),
            availableLocales(){
                return this.locales;
            },
            currentLocale(){
                return this.$i18n.locale;
            }
        },
        methods:{
            ...mapMutations(['setLocale'])
        }
    }
</script>