<template>
  <svg viewBox="-12 -12 24 24" height="1em" width="1em" fill="transparent" :stroke-width="lineThickness" stroke-linecap="round">
    <circle r="10" cx="0" cy="0" :stroke="trackColor"></circle>
    <circle r="10" cx="0" cy="0" :stroke="spinnerColor" :stroke-dasharray="20*Math.PI">
      <animate
          attributeName="stroke-dashoffset"
          values="0;62.8;0"
          dur="2s"
          repeatCount="indefinite"/>
      <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          from="0 0 0"
          to="360 0 0"
          dur="2s"
          repeatCount="indefinite"/>
    </circle>
  </svg>
</template>
<script>
export default {
  name: 'IndeterminateSpinner',
  props: {
    spinnerColor: {
      type: String,
      default: "currentColor",
    },
    trackColor: {
      type: String,
      default: "transparent",
    },
    lineThickness: {
      type: Number,
      default: 4
    }
  }
}
</script>
