<template>
  <div class="option-result col-12" style="overflow-x: auto">
    <horizontal-bars v-if="resultChart === 'horizontal_bars'" :theme="theme" :chart-data="chartData" :options="chartOptions" class="option-content w-100 position-relative"/>
    <vertical-bars v-else-if="resultChart === 'vertical_bars'" :theme="theme" :chart-data="chartData" :options="chartOptions" class="option-content w-100 position-relative" :style="{minWidth: `${Math.max(40, labelledCounts.length*2)}em`}"/>
    <pie-chart v-else-if="resultChart === 'pie'" :theme="theme" :chart-data="chartData" :options="chartOptions" class="option-content w-100 position-relative" style="min-width: 40em"/>
  </div>
</template>
<script>
import HorizontalBars from "./charts/HorizontalBars.vue"
import {mapGetters, mapState} from "vuex";
import VerticalBars from "./charts/VerticalBars.vue";
import PieChart from "./charts/PieChart.vue";
import { flattenOptions } from "../../entrypoints/shared/contest_utilities";

export default {
  name: 'NormalResultChart',
  components: {PieChart, VerticalBars, HorizontalBars},
  props: {
    resultData: {
      type: Object,
      required: true
    },
    resultChart: {
      type: String,
      default: "horizontal_bars",
    },
    options: {
      type: Array,
      required: true,
    },
    theme: {
      type: String,
      default: 'light'
    },
    chartOptions: {
      type: Object,
      default: ()=>({})
    }
  },
  methods: {
    flattenOptions,
    truncateLabel(label, maxLength=110, ending='…'){
      return label.length > maxLength ?
          label.slice(0,maxLength)+ending :
          label;
    }
  },
  computed:{
    ...mapGetters(['firstAvailableLocale']),
    ...mapState(['election']),
    labelledCounts(){
      return this.resultData.optionSortedCounts.map(row => {
        let loptions = this.flattenOptions(this.options)
        let option = loptions.find(o => o.reference === row[0])
        return ({
          label: this.truncateLabel(option.title[this.firstAvailableLocale] || Object.values(option.title)[0] || 'missing translation'),
          count: row[1],
          elected: this.resultData.elected.includes(option.reference),
          tied: this.resultData.tied.includes(option.reference),
          disabled: this.resultData.disabledOptions.includes(option.reference)
        });
      })
    },
    chartData() {
      let colors = this.labelledCounts.map((count, index) => {
        if(count.elected){
          return `#d4edda`
        } else if(count.tied){
          return `#fff3cd`
        } else {
          return `#6c757d`
        }
      })
      return {
        labels: this.labelledCounts.map(count => {
          return count.label
        }),
        datasets: [{
          backgroundColor: colors,
          data: this.labelledCounts.map(count => {
            return count.count;
          })
        }]
      }
    }
  }
}
</script>