// Set the name of the hidden property and the change event for visibility
import {reactive} from "vue";

const visibility = reactive({
    visible: true
});

// Handle page visibility change
console.debug("binding visibility handler")
document.addEventListener("visibilitychange", function () {
    console.debug(`page visibility changed to ${document.hidden ? 'hidden' : 'visible'}`)
    visibility.visible = !document.hidden;
}, false);

export default visibility