<template>
  <Transition name="fade">
    <div class="position-absolute bg-blur-dark p-3 m-3 rounded" style="z-index: 10" v-if="connectionLost">
      <div v-if="lostConnectionToInternet" class="text-danger hstack gap-3" v-tooltip="$t('js.connection_indicator.please_connect_your_device_to_the_internet')">
        <i class="fas fa-2x fa-exclamation-triangle"></i>
        <span v-text="$t('js.connection_indicator.browser_down')"/>
      </div>
      <div v-else-if="lostConnectionToServer" class="text-warning hstack gap-3" v-tooltip="$t('js.connection_indicator.connection_lost_server_tooltip')">
        <i class="fas fa-2x fa-spinner fa-spin"/>
        <span v-text="$t('js.connection_indicator.connection_lost_server_summary')"/>
      </div>
      <div v-else-if="lostConnectionToWebsocket" class="text-warning hstack gap-3" v-tooltip="$t('js.connection_indicator.connection_lost_websocket_tooltip')">
        <i class="fas fa-2x fa-spinner fa-spin"/>
        <span v-text="$t('js.connection_indicator.connection_lost_websocket_summary')"/>
      </div>
    </div>
  </Transition>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Modal from "../voting/modals/Modal.vue";
export default {
  name: "ConnectionIndicator",
  components: {Modal},
  data() {
    return {
      lostConnectionToInternet: false,
      attemptingReconnect: false,
    }
  },
  computed: {
    ...mapState(['lostConnectionToServer', 'lostConnectionToWebsocket']),
    connectionLost() {
      return this.lostConnectionToInternet || this.lostConnectionToServer || this.lostConnectionToWebsocket;
    }
  },
  watch: {
    async lostConnectionToServer(newValue) {
      if (!newValue) return;

      await this.attemptReconnect();
    }
  },
  methods: {
    ...mapActions(['checkConnection']),
    async attemptReconnect() {
      this.attemptingReconnect = true
      try {
        await this.checkConnection()
      } catch (e) {
        setTimeout(await this.attemptReconnect, 2000)
      }
      this.attemptingReconnect = false;
    },
    setLostConnectTrue() {
      this.lostConnectionToInternet = true
    },
    setLostConnectFalse() {
      this.lostConnectionToInternet = false
    }
  },
  mounted() {
    window.addEventListener('offline', this.setLostConnectTrue);
    window.addEventListener('online', this.setLostConnectFalse);
  }
}
</script>